var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _vm.user
          ? _c("el-card", { staticClass: "box-card" }, [
              _c("div", { staticClass: "text item" }, [
                _vm._v(" Company: " + _vm._s(_vm.user.companyName) + " ")
              ]),
              _c("div", { staticClass: "text item" }, [
                _vm._v(" Name: " + _vm._s(_vm.user.fullName) + " ")
              ]),
              _c("div", { staticClass: "text item" }, [
                _vm._v(" GST: " + _vm._s(_vm.user.gstNumber) + " ")
              ])
            ])
          : _vm._e(),
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _c("el-divider", { attrs: { "content-position": "left" } }, [
              _vm._v(" User documents ")
            ]),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading"
                  }
                ],
                key: _vm.tableKey,
                staticStyle: { width: "100%" },
                attrs: { data: _vm.usersDocumentList, border: "", fit: "" }
              },
              [
                _c("el-table-column", {
                  attrs: { width: "auto", label: "Name", prop: "name" }
                }),
                _c("el-table-column", {
                  attrs: { label: "File", prop: "file" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: {
                                type: "primary",
                                href: scope.row.file,
                                target: "_blank"
                              }
                            },
                            [_vm._v(" view file ")]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Status" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "Select" },
                              model: {
                                value: _vm.formData.bulk[scope.$index].status,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.formData.bulk[scope.$index],
                                    "status",
                                    $$v
                                  )
                                },
                                expression: "formData.bulk[scope.$index].status"
                              }
                            },
                            _vm._l(["Approved", "Rejected"], function(item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item }
                              })
                            }),
                            1
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Comments" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm.formData.bulk[scope.$index].status === "Rejected"
                            ? _c("el-input", {
                                attrs: { placeholder: "Enter reject reason" },
                                model: {
                                  value:
                                    _vm.formData.bulk[scope.$index]
                                      .rejectReason,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData.bulk[scope.$index],
                                      "rejectReason",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formData.bulk[scope.$index].rejectReason"
                                }
                              })
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _c("br"),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }