import request from '@/utils/request';
export var defaultUsersDocumentData = {
  id: 0,
  enabled: true,
  userId: undefined,
  name: '',
  file: null,
  status: undefined,
  rejectReason: null,
  user: {
    id: undefined
  }
};
export var getUsersDocuments = function getUsersDocuments(params) {
  return request({
    url: '/users-documents',
    method: 'get',
    params: params
  });
};
export var getUsersDocumentById = function getUsersDocumentById(id) {
  return request({
    url: "/users-documents/".concat(id),
    method: 'get'
  });
};
export var getDocumentNames = function getDocumentNames() {
  return request({
    url: '/users-documents/list-names',
    method: 'get'
  });
};
export var updateUsersDocument = function updateUsersDocument(id, data) {
  return request({
    url: "/users-documents/".concat(id),
    method: 'patch',
    data: data
  });
};
export var createUsersDocument = function createUsersDocument(data) {
  return request({
    url: '/users-documents/',
    method: 'post',
    data: data
  });
};
export var bulkManageUsersDocument = function bulkManageUsersDocument(data) {
  return request({
    url: '/users-documents/bulk',
    method: 'post',
    data: data
  });
};